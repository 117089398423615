<template>
  <div>
    <v-row>
      <v-col cols="12" xl="8" lg="8" md="8">
        <v-text-field
          dense
          outlined
          autofocus
          maxlength="50"
          counter
          clearable
          :label="$t('banner.data.name')"
          :messages="$t('banner.data.name.helper')"
          v-model="banner.name"
        >
          <template v-slot:message="{ message }">
            <div class="blue--text">
              {{message}}
            </div>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" xl="4" lg="4" md="4">
        <v-switch
          v-if="!disabledFullscreenOption"
          class="mt-1"
          dense
          inset
          color="green"
          :label="$t('banner.data.fullscreen')"
          v-model="banner.fullscreen"
        ></v-switch>
      </v-col>

      <v-col cols="12" xl="8" lg="8" md="8">
        <v-text-field
          dense
          outlined
          maxlength="5"
          counter
          :label="$t('banner.data.interval')"
          :messages="$t('unit.ms')"
          type="number"
          v-model.number="banner.interval"
        >
          <template v-slot:message="{ message }">
            <div class="blue--text">
              {{message}}
            </div>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" xl="4" lg="4" md="4">
        <v-switch
          class="mt-1"
          dense
          inset
          color="green"
          :label="$t('banner.data.autoplay')"
          v-model="banner.autoplay"
        ></v-switch>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formDataItem: {
      type: Object,
    },
    value: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    banner: null,
  }),
  computed: {
    disabledFullscreenOption() {
      return this.formDataItem.disabledFullscreenOption === true
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.banner = this.$eagleLodash.cloneDeep(this.value)
      },
    },
    banner: {
      deep: true,
      handler() {
        if(this.$eagleLodash.isEqual(this.value, this.banner)) return
        this.$emit('input', this.$eagleLodash.cloneDeep(this.banner))
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
